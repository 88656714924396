body {
  margin: 0;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: normal;
}

body.lang-am {
  font-family: 'Noto Sans Armenian', 'Inter', sans-serif;
}

a.nav-link {
  color: black;
  text-decoration: none;
}
