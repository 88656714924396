.header :global {
  background: linear-gradient(360deg, #DBDAD9 59.09%, rgba(231, 227, 224, 0) 158.33%);
  padding-top: 24px;

  .logo {
    font-size: 28px;
    padding-bottom: 5px;
  }

  .language-switcher {
    display: flex;
    gap: 8px;
    
    span {
      cursor: pointer;
    }
  }

  .nav-link {
    color: rgba(28, 39, 47, 1);
    &.active {
      background-color: rgba(53, 80, 100, 0.15);
      color: rgba(28, 39, 47, 1);
    }
  }
}
