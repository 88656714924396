@import "scss/variables";

.footer :global {
  background: linear-gradient(180deg, #E7E3E0 62.77%, rgba(231, 227, 224, 0) 156.38%);
  padding-bottom: 12px;
  padding-top: 12px;

  .footer-container {
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
